import React from 'react';
import logo from './logo.svg';
import './App.css';

const display = (number) => {
  return Math.round(number * 100) / 100;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infection_rate: 1,
      specificity: 99.8,
      sensitivity: 100.0,
      displayed_specificity: "99.8",
      displayed_false_positive: "0.2",
      displayed_sensitivity: "100.0",
      displayed_false_negative: "0.0"
    };
  }

  render() {
    const infection_rate = this.state.infection_rate;
    const population_hadvirus_positivetest = infection_rate/100 * this.state.sensitivity/100;
    const population_hadvirus_negativetest = infection_rate/100 * (1 - this.state.sensitivity/100);
    const population_novirus_positivetest = (1 - infection_rate/100) * (1 - this.state.specificity/100);
    const population_novirus_negativetest = (1 - infection_rate/100) * this.state.specificity/100;

    const P_had_given_positive = Math.round(population_hadvirus_positivetest / (population_hadvirus_positivetest + population_novirus_positivetest) * 10000) / 100;
    const P_no_given_negative = Math.round(population_novirus_negativetest / (population_novirus_negativetest + population_hadvirus_negativetest) * 10000) / 100;

    return (
      <div className="App">
        <header>
          <h2>Antibody Testing</h2>
          <p>If a Coronavirus antibody test is positive, what are the odds you actually had Coronavirus?</p>
        </header>
        <div class="input row"><div class="col-xl-8 offset-xl-2">
          <h3>Input values:</h3>
          <p><input size="2" value={this.state.infection_rate} onChange={this.changeInfectionRate}></input> % of the population has been infected at some point</p>
          <p>The test has <input size="5" value={this.state.displayed_specificity} onChange={this.changeSpecificity}></input> % specificity (or in other words, it has a false positive rate of <input size="4" value={this.state.displayed_false_positive} onChange={this.changeFalsePositive}></input> %)</p>
          <p>The test has <input size="5" value={this.state.displayed_sensitivity} onChange={this.changeSensitivity}></input> % sensitivity (or the false negative rate is <input size="4" value={this.state.displayed_false_negative} onChange={this.changeFalseNegative}></input> %)</p>
          <p>Prefill specificity and sensitivity with values for the: <a href="#" onClick={this.prefill} name="abbott">Abbott</a> / <a href="#" onClick={this.prefill} name="diasorin">Diasorin</a> / <a href="#" onClick={this.prefill} name="eurofins">Eurofins (Babylon)</a> / <a href="#" onClick={this.prefill} name="roche">Roche</a> test</p>
        </div></div>
        <div class="input row"><div class="col-xl-8 offset-xl-2">
          <h3>Results</h3>
          <p>If you take the test and it is <em>positive</em>, there is a <strong>{P_had_given_positive}% chance that you have had Coronavirus</strong></p>
          <p>If you take the test and it is <em>negative</em>, there is a <strong>{P_no_given_negative}% chance that you have not had Coronavirus</strong></p>
        </div></div>
        <div class="input row"><div class="col-xl-8 offset-xl-2">
          <h3>Explanation</h3>
          <p>Even though a test itself might be 99% accurate, so few people have had Coronavirus (we think) that as many people will get a positive test due to that 1% inaccuracy as will get one for actually having the disease. This is called the "base rate fallacy" and you can read more about it <a href="https://en.wikipedia.org/wiki/Base_rate_fallacy">on Wikipedia</a>.</p>
          <p>I built this calculator so you can plainly see what the specificity/sensitivity values for tests actually <em>mean</em> when applied to the current population, and see how test accuracy changes not just with specificity and sensitivity, but also with the infection rate.</p>
        </div></div>
        <Maths infection_rate={infection_rate} sensitivity={this.state.sensitivity} specificity={this.state.specificity} population_hadvirus_positivetest={population_hadvirus_positivetest} population_hadvirus_negativetest={population_hadvirus_negativetest} population_novirus_positivetest={population_novirus_positivetest} population_novirus_negativetest={population_novirus_negativetest} P_had_given_positive={P_had_given_positive} P_no_given_negative={P_no_given_negative}/>
      </div>
    );
  }

  prefill = (event) => {
    event.preventDefault();
    switch(event.target.name) {
      case "roche":
        this.setSpecificity(99.8);
        this.setSensitivity(100);
        break;
      case "eurofins":
        this.setSpecificity(99.5);
        this.setSensitivity(98.5);
        break;
      case "abbott":
        this.setSpecificity(99.5);
        this.setSensitivity(100);
        break;
      case "diasorin":
        this.setSpecificity(97.4);
        this.setSensitivity(98.5);
        break;
    }
  }

  changeInfectionRate = (event) => {
    this.setState({infection_rate: event.target.value});
  }

  changeSpecificity = (event) => {
    this.setSpecificity(event.target.value);
  }

  setSpecificity = (displayed_specificity) => {
    const specificity = Number(displayed_specificity);
    const displayed_false_positive = Math.round((100 - specificity) * 100)/100;
    this.setState({specificity: specificity, displayed_specificity: displayed_specificity, displayed_false_positive: displayed_false_positive});
  }

  changeFalsePositive = (event) => {
    const displayed_false_positive = event.target.value;
    const specificity = 100 - Number(displayed_false_positive);
    const displayed_specificity = Math.round(specificity * 100) / 100;
    this.setState({specificity: specificity, displayed_specificity: displayed_specificity, displayed_false_positive: displayed_false_positive});
  }

  changeSensitivity = (event) => {
    this.setSensitivity(event.target.value);
  }

  setSensitivity = (displayed_sensitivity) => {
    const sensitivity = Number(displayed_sensitivity);
    const displayed_false_negative = Math.round((100 - sensitivity) * 100)/100;
    this.setState({sensitivity: sensitivity, displayed_sensitivity: displayed_sensitivity, displayed_false_negative: displayed_false_negative});
  }

  changeFalseNegative = (event) => {
    const displayed_false_negative = event.target.value;
    const sensitivity = 100 - Number(displayed_false_negative);
    const displayed_sensitivity = Math.round(sensitivity * 100) / 100;
    this.setState({sensitivity: sensitivity, displayed_sensitivity: displayed_sensitivity, displayed_false_negative: displayed_false_negative});
  }
}

class Maths extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show: false};
  }

  render() {
    if(this.state.show) {
      return (
        <React.Fragment>
          <div class="input row"><div class="col-xl-8 offset-xl-2">
            <p><a href="#" onClick={this.hide}>Please hide the maths again.</a></p>
          </div></div>
          <div class="input row"><div class="col-xl-12">
            <p>
              population who had the virus = {this.props.infection_rate}%<br/>
            </p>
            <p>
              population who have the virus and test positive = infection rate x sensitivity = {this.props.infection_rate}% x {display(this.props.sensitivity)}% = {display(this.props.population_hadvirus_positivetest * 100)}%<br/>
              population who have the virus and test negative = infection rate x (1 - sensitivity) = {this.props.infection_rate}% x {display(100 - this.props.sensitivity)}% = {display(this.props.population_hadvirus_negativetest * 100)}%<br/>
              population who didn't have the virus and test negative = (1 - infection rate) x specificity) = {display(100 - this.props.infection_rate)}% x {display(this.props.specificity)}% = {display(this.props.population_novirus_negativetest)}%<br/>
              population who didn't have the virus and test positive = (1 - infection rate) x (1 - specificity) = {display(100 - this.props.infection_rate)}% x {display(100 - this.props.specificity)}% = {display(this.props.population_novirus_positivetest * 100)}%<br/>
            </p>
            <p>
              population who have the virus if they tested positive = had virus and tested positive ÷ (had virus and tested positive + no virus and tested positive) = {display(this.props.population_hadvirus_positivetest * 100)}% ÷ ({display(this.props.population_hadvirus_positivetest * 100)}% + {display(this.props.population_novirus_positivetest * 100)}%) = {display(this.props.P_had_given_positive)}%<br/>
              population who didn't have the virus if they tested negative = no virus and tested negative ÷ (no virus and tested negative + had virus and tested negative) = {display(this.props.population_novirus_negativetest)}% ÷ ({display(this.props.population_novirus_negativetest)}% + {display(this.props.population_hadvirus_negativetest * 100)}%) = {display(this.props.P_no_given_negative)}%
            </p>
          </div></div>
        </React.Fragment>
      );
    }
    else {
      return <div class="input row"><div class="col-xl-8 offset-xl-2"><p><a href="#" onClick={this.show}>Show me the maths!</a></p></div></div>
    }
  }

  show = () => {
    this.setState({show: true});
  }

  hide = () => {
    this.setState({show: false});
  }
}

export default App;

